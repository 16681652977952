import React from "react"
import { graphql, PageProps } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"
import distributionDeMusicEnLigne from '../config/contents/distribution-de-music-en-ligne';

import Image from "../components/image";
import GradientButton from "../components/gradient-button";
import { signup, nosPlatformes } from '../assets/data/links.json';
import Card from "../components/card";
import Platforms from "../components/platforms";

import uploadIcon from '../assets/icons/upload.svg';
import worldIcon from '../assets/icons/world.svg';
import cashIcon from '../assets/icons/cash.svg';
import handCashIcon from '../assets/icons/hand-cash.svg';
import dropIcon from '../assets/icons/drop.svg';
import barsIcon from '../assets/icons/bars.svg';
import graphIcon from '../assets/icons/graph.svg';

import '../assets/css/distribution-de-musique-en-ligne.css';

const artistsAndLabelsAlt = [
  'Benguerna studio',
  'Villa prod',
  'Acapela studio',
  'Go prod dz',
  'Djalil Palermo feat Housseyn Benguerna - Roho Qololha',
  'Faycel Sghir feat Housseyn Benguerna - ya quelbi samahni'
];
const DistributionDeMusicEnLignePage: React.FC<PageProps<any>> = ({ data }) => {
  const { bgWaves, bgHalfWorld, site, whyDropzikBg, artistsAndLabels } = data;
  const { title, path, description } = site?.siteMetadata?.pages?.distributionOnline;
  const heroBgStyle = {
    backgroundImage: `url(${bgWaves?.childImageSharp?.fluid?.src})`,
  };

  return (
    <Layout>
      <SEO title={title} description={description} path={path} image={bgWaves?.childImageSharp.fluid.src} />

      <div className="distribution-container">

        <section className="hero__image-bg bg-darkengrey text-white position-relative" style={heroBgStyle}>

          <Image
            className='w-100 right__bg position-absolute h-100 bottom_0 right_0'
            src={bgHalfWorld?.childImageSharp?.fluid}
            height={bgHalfWorld?.childImageSharp?.fluid?.presentationHeight}
            width={bgHalfWorld?.childImageSharp?.fluid?.presentationWidth}
          />

          <article className="container">
            <div className="container__row row">
              <div className="hero__content d-flex flex-column justify-content-center">
                <h1 className="title mt-0 mb-3 mb-md-4 p-0">
                  <span>
                    {distributionDeMusicEnLigne.section1.mainTitle.part1}{` `}
                  </span>
                  <strong className="d-bold">
                    {distributionDeMusicEnLigne.section1.mainTitle.part2}
                  </strong>
                </h1>
                <p className='mb-3 mb-md-5 p-0 pr-0 pr-md-2'>{distributionDeMusicEnLigne.section1.description}</p>
                <div className='hero__action'>
                  <GradientButton className='btn-style w-auto d-bold' to={signup} external>
                    {distributionDeMusicEnLigne.section1.linkLabel}
                  </GradientButton>
                </div>
              </div>
            </div>
          </article>

        </section>

        <section className="bg-grey overflow-hidden text-white">

          <div className="container">
            <div className="container__row row">
              <h2 className="title mb-1">
                COMMENT  <strong className="d-bold">VENDRE</strong> SA{` `}
                <strong className="d-bold">MUSIQUE</strong> EN{` `}
                <strong className="d-bold">LIGNE</strong>?
              </h2>
              <p className='text__block mb-5 w-75'>{distributionDeMusicEnLigne.section2.description}</p>

              <div className="container__row row d-flex flex-column flex-sm-row cards__block">

                <Card
                  Image={
                    <img src={uploadIcon} alt={distributionDeMusicEnLigne.section2.cards.card1.title} />
                  }
                  imageContainerClassName='text-center'
                  titleClassName='fsz-20'
                  title={distributionDeMusicEnLigne.section2.cards.card1.title}
                  content={distributionDeMusicEnLigne.section2.cards.card1.description}
                />

                <Card
                  Image={
                    <img src={worldIcon} alt={distributionDeMusicEnLigne.section2.cards.card2.title} />
                  }
                  imageContainerClassName='text-center'
                  titleClassName='fsz-20'
                  title={distributionDeMusicEnLigne.section2.cards.card2.title}
                  content={distributionDeMusicEnLigne.section2.cards.card2.description}
                />

                <Card
                  Image={
                    <img src={cashIcon} alt={distributionDeMusicEnLigne.section2.cards.card3.title} />
                  }
                  imageContainerClassName='text-center'
                  titleClassName='fsz-20'
                  title={distributionDeMusicEnLigne.section2.cards.card3.title}
                  content={distributionDeMusicEnLigne.section2.cards.card3.description}
                />

              </div>

            </div>
          </div>

        </section>

        <section className="why-dropzik__block d-flex flex-column flex-md-row overflow-hidden bg-grey text-white position-relative">

          <div className='image__block w-50 h-100 position-absolute bottom_0 left_0 pr-2'>
            <Image
              className='image__fluid image-full-height image_position_right_0 opacity_6 blend-mode_lighten'
              src={whyDropzikBg?.childImageSharp?.fluid}
            />
          </div>

          <div className="container">
            <div className="container__row row">
              <article className="d-flex flex-column flex-lg-row position-relative">

                <div className="flex_1 left-side ">

                  <div className='h-75 d-flex flex-column justify-content-center'>
                    <h2 className="title mt-4 mb-3 pt-3">{distributionDeMusicEnLigne.section3.mainTitle}</h2>
                    <p className='text__description font-weight-light mb-5'>{distributionDeMusicEnLigne.section3.description}</p>
                    <p className='quote__description text-uppercase'>
                      “Une <strong className="d-bold">solution</strong>{` `}
                      pour les <strong className="d-bold">artistes</strong>,{` `}
                      les <strong className="d-bold">maisons de disque</strong>{` `}
                      et les <strong className="d-bold">managers</strong>”.
                    </p>
                  </div>

                </div>

                <div className="flex_1 right-side flex-column">

                  <ul className="collection m-0 p-0 list-unstyled  right-side d-flex flex-column">

                    <li className="collection__item mb-3">
                      <div className='list d-flex align-items-center mb-3'>
                        <div className="list__avatar-block">
                          <img className='list__item-avatar' src={handCashIcon} alt={distributionDeMusicEnLigne.section3.cards.card1.title} />
                        </div>
                        <h4 className="list-item__title text-uppercase m-0 text-red fsz-20 d-bold">{distributionDeMusicEnLigne.section3.cards.card1.title}</h4>
                      </div>
                      <p className='list__item-content font-weight-light'>{distributionDeMusicEnLigne.section3.cards.card1.description}</p>
                    </li>
                    <li className="collection__item mb-3">
                      <div className='list d-flex align-items-center mb-3'>
                        <div className="list__avatar-block">
                          <img className='list__item-avatar' src={dropIcon} alt={distributionDeMusicEnLigne.section3.cards.card2.title} />
                        </div>
                        <h4 className="list-item__title text-uppercase m-0 text-red fsz-20 d-bold">{distributionDeMusicEnLigne.section3.cards.card2.title}</h4>
                      </div>
                      <p className='list__item-content font-weight-light'>{distributionDeMusicEnLigne.section3.cards.card2.description}</p>
                    </li>
                    <li className="collection__item mb-3">
                      <div className='list d-flex align-items-center mb-3'>
                        <div className="list__avatar-block">
                          <img className='list__item-avatar' src={barsIcon} alt={distributionDeMusicEnLigne.section3.cards.card3.title} />
                        </div>
                        <h4 className="list-item__title text-uppercase m-0 text-red fsz-20 d-bold">{distributionDeMusicEnLigne.section3.cards.card3.title}</h4>
                      </div>
                      <p className='list__item-content font-weight-light'>{distributionDeMusicEnLigne.section3.cards.card3.description}</p>
                    </li>
                    <li className="collection__item mb-2">
                      <div className='list d-flex align-items-center mb-3'>
                        <div className="list__avatar-block">
                          <img className='list__item-avatar' src={graphIcon} alt={distributionDeMusicEnLigne.section3.cards.card4.title} />
                        </div>
                        <h4 className="list-item__title text-uppercase m-0 text-red fsz-20 d-bold">{distributionDeMusicEnLigne.section3.cards.card4.title}</h4>
                      </div>
                      <p className='list__item-content font-weight-light'>{distributionDeMusicEnLigne.section3.cards.card4.description}</p>
                    </li>

                  </ul>

                  <div className="d-flex justify-content-end mt-4">
                    <GradientButton className='btn-style d-bold' to={signup} external>
                      {distributionDeMusicEnLigne.section3.linkLabel}
                    </GradientButton>
                  </div>

                </div>

              </article>
            </div>
          </div>

        </section>

        <section className="bg-grey text-white py-3 py-md-5">
          <div className="container pt-0 pt-md-3 mb-0 mb-md-5 pb-0 pb-5">
            <div className="container__row row flex-column pb-0 pb-md-5">
              <h2 className="title m-0 mb-3">
                Nos <strong className="d-bold">artistes</strong> et <strong className="d-bold">labels</strong>
              </h2>
              <p className='fsz-20 mb-3 mb-md-5'>{distributionDeMusicEnLigne.section5.description}</p>
              <div className="images-gallery__block d-flex flex-wrap">
                {
                  artistsAndLabels.edges.map(({ node }: any, index: number) => (
                    <div
                      className="images-gallery__item d-flex align-items-center"
                      key={`artist-label-${index}`}
                    >
                      <Image
                        src={node?.childImageSharp?.fluid}
                        className="w-100"
                        alt={artistsAndLabelsAlt[index]}
                      />
                    </div>
                  ))
                }
              </div>
            </div>
          </div>
        </section>

        <Platforms
          link={nosPlatformes}
          linkLabel={distributionDeMusicEnLigne.section4.linkLabel}
          headerClassName='bg-lightgrey'
          titleClassName='pt-4'
          bottomWave>
          <div className='card__content'>
            <p className="mb-4">
              {distributionDeMusicEnLigne.section4.description.part1}
            </p>
            <p className="m-0">
              {distributionDeMusicEnLigne.section4.description.part2}
            </p>
          </div>
        </Platforms>

      </div>

    </Layout>
  );
}

export const query = graphql`
  query distributionOnlineQuery {
    site {
      siteMetadata {
        lang
        pages {
          distributionOnline {
            title
            path
            description
          }
        }
      }
    }
    bgWaves: file(relativePath: { eq: "music-distribution/bg-waves.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 1440 maxHeight: 497) {
          ...GatsbyImageSharpFluid_withWebp
          presentationHeight
          presentationWidth
        }
      }
    }
    bgHalfWorld: file(relativePath: { eq: "music-distribution/bg-half-world.png" }) {
      childImageSharp {
        fluid(maxWidth: 490) {
          ...GatsbyImageSharpFluid_withWebp
          presentationHeight
          presentationWidth
        }
      }
    }
    whyDropzikBg: file(relativePath: { eq: "music-distribution/why-dropzik-bg.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 710) {
          ...GatsbyImageSharpFluid_withWebp
          presentationHeight
          presentationWidth
        }
      }
    }
    artistsAndLabels: allFile(
      filter: {
        extension: {
          regex: "/(jpg)|(jpeg)|(png)/"
        }, 
        relativeDirectory: {
          eq: "music-distribution/artists-and-labels"
        }
      }
      sort: {
        fields: [name],
        order: ASC
      }
      ) {
      edges {
        node {
          name
          childImageSharp {
            fluid(maxWidth: 200) {
              ...GatsbyImageSharpFluid_withWebp
              presentationHeight
              presentationWidth
            }
          }
        }
      }
    }
  }
`;

export default DistributionDeMusicEnLignePage;
