import React from 'react';
import { KeyValueObject } from '../../types/shared';

/**
 * @name Wave
 * @author siemah
 * @param HTMLElement
 * @description Wave svg component
 */
export default function Wave({ className = '', stopColorFrom, stopColorTo, idFillName = '' }: KeyValueObject<string>) {
  return (
    <svg viewBox="0 0 294 294" fill={`none`} className={className}>
      <path d="M134.75 220.5C134.75 227.262 140.238 232.75 147 232.75C153.762 232.75 159.25 227.262 159.25 220.5V73.5C159.25 66.738 153.762 61.25 147 61.25C140.238 61.25 134.75 66.738 134.75 73.5V220.5Z" fill={`url(#${idFillName}_paint0_linear)`} />
      <path d="M12.25 73.5C5.48798 73.5 0 78.988 0 85.75V208.25C0 215.012 5.48798 220.5 12.25 220.5C19.012 220.5 24.5 215.012 24.5 208.25V85.75C24.5 78.988 19.012 73.5 12.25 73.5Z" fill={`url(#${idFillName}_paint1_linear)`} />
      <path d="M116.375 196V98C116.375 91.238 110.887 85.75 104.125 85.75C97.363 85.75 91.875 91.238 91.875 98V196C91.875 202.762 97.363 208.25 104.125 208.25C110.887 208.25 116.375 202.762 116.375 196Z" fill={`url(#${idFillName}_paint2_linear)`} />
      <path d="M61.25 183.75C68.012 183.75 73.5 178.262 73.5 171.5V122.5C73.5 115.738 68.012 110.25 61.25 110.25C54.488 110.25 49 115.738 49 122.5V171.5C49 178.262 54.488 183.75 61.25 183.75Z" fill={`url(#${idFillName}_paint3_linear)`} />
      <path d="M202.125 196V98C202.125 91.238 196.637 85.75 189.875 85.75C183.113 85.75 177.625 91.238 177.625 98V196C177.625 202.762 183.113 208.25 189.875 208.25C196.637 208.25 202.125 202.762 202.125 196Z" fill={`url(#${idFillName}_paint4_linear)`} />
      <path d="M281.75 73.5C274.988 73.5 269.5 78.988 269.5 85.75V208.25C269.5 215.012 274.988 220.5 281.75 220.5C288.512 220.5 294 215.012 294 208.25V85.75C294 78.988 288.512 73.5 281.75 73.5Z" fill={`url(#${idFillName}_paint5_linear)`} />
      <path d="M232.75 110.25C225.988 110.25 220.5 115.738 220.5 122.5V171.5C220.5 178.262 225.988 183.75 232.75 183.75C239.512 183.75 245 178.262 245 171.5V122.5C245 115.738 239.512 110.25 232.75 110.25Z" fill={`url(#${idFillName}_paint6_linear)`} />
      <path d="M134.75 281.75C134.75 288.512 140.238 294 147 294C153.762 294 159.25 288.512 159.25 281.75V269.5C159.25 262.738 153.762 257.25 147 257.25C140.238 257.25 134.75 262.738 134.75 269.5V281.75Z" fill={`url(#${idFillName}_paint7_linear)`} />
      <path d="M147 36.75C153.762 36.75 159.25 31.262 159.25 24.5V12.25C159.25 5.48798 153.762 0 147 0C140.238 0 134.75 5.48798 134.75 12.25V24.5C134.75 31.262 140.238 36.75 147 36.75Z" fill={`url(#${idFillName}_paint8_linear)`} />
      <defs>
        <linearGradient id={`${idFillName}_paint0_linear`} x1="147" y1="61.25" x2="147" y2="232.75" gradientUnits="userSpaceOnUse">
          <stop stopColor={stopColorFrom} />
          <stop offset="1" stopColor={stopColorTo} />
        </linearGradient>
        <linearGradient id={`${idFillName}_paint1_linear`} x1="12.25" y1="73.5" x2="12.25" y2="220.5" gradientUnits="userSpaceOnUse">
          <stop stopColor={stopColorFrom} />
          <stop offset="1" stopColor={stopColorTo} />
        </linearGradient>
        <linearGradient id={`${idFillName}_paint2_linear`} x1="104.125" y1="85.75" x2="104.125" y2="208.25" gradientUnits="userSpaceOnUse">
          <stop stopColor={stopColorFrom} />
          <stop offset="1" stopColor={stopColorTo} />
        </linearGradient>
        <linearGradient id={`${idFillName}_paint3_linear`} x1="61.25" y1="110.25" x2="61.25" y2="183.75" gradientUnits="userSpaceOnUse">
          <stop stopColor={stopColorFrom} />
          <stop offset="1" stopColor={stopColorTo} />
        </linearGradient>
        <linearGradient id={`${idFillName}_paint4_linear`} x1="189.875" y1="85.75" x2="189.875" y2="208.25" gradientUnits="userSpaceOnUse">
          <stop stopColor={stopColorFrom} />
          <stop offset="1" stopColor={stopColorTo} />
        </linearGradient>
        <linearGradient id={`${idFillName}_paint5_linear`} x1="281.75" y1="73.5" x2="281.75" y2="220.5" gradientUnits="userSpaceOnUse">
          <stop stopColor={stopColorFrom} />
          <stop offset="1" stopColor={stopColorTo} />
        </linearGradient>
        <linearGradient id={`${idFillName}_paint6_linear`} x1="232.75" y1="110.25" x2="232.75" y2="183.75" gradientUnits="userSpaceOnUse">
          <stop stopColor={stopColorFrom} />
          <stop offset="1" stopColor={stopColorTo} />
        </linearGradient>
        <linearGradient id={`${idFillName}_paint7_linear`} x1="147" y1="257.25" x2="147" y2="294" gradientUnits="userSpaceOnUse">
          <stop stopColor={stopColorFrom} />
          <stop offset="1" stopColor={stopColorTo} />
        </linearGradient>
        <linearGradient id={`${idFillName}_paint8_linear`} x1="147" y1="0" x2="147" y2="36.75" gradientUnits="userSpaceOnUse">
          <stop stopColor={stopColorFrom} />
          <stop offset="1" stopColor={stopColorTo} />
        </linearGradient>
      </defs>
    </svg>

  );
}
