import { graphql, useStaticQuery } from 'gatsby';
import React, { ReactNode } from 'react';
import GradientButton from '../gradient-button';
import Image from '../image';
import Wave from '../svg/wave';

import './style.css';

interface PlatformsProps {
  topWave?: boolean;
  bottomWave?: boolean;
  children?: ReactNode;
  link?: string;
  linkLabel?: string;
  headerClassName?: string;
  titleClassName?: string;
}
/**
 * Present a list of most popular music platforms logo
 * @name Platforms
 * @description this component used to present a platform where the music of artist will upload
 * used on 2page home page('/') and music distribution('/distribution-de-musique-en-ligne/')
 */
export default function Platforms({ topWave = false, bottomWave = false, children, titleClassName = '', headerClassName = '', link, linkLabel }: PlatformsProps) {

  const data = useStaticQuery(graphql`
    query {
      platformsImage: file(relativePath: { eq: "home/platforms.png" }) {
        childImageSharp {
          fluid(maxWidth:996) {
            ...GatsbyImageSharpFluid_withWebp
            presentationHeight
            presentationWidth
          }
        }
      }
    }
`);

  return (
    <section
      className={`platforms__block bg-white position-relative overflow-hidden pt-3 pt-md-4 ${link ? 'with-action' : ''}`}>

      {
        topWave && (
          <Wave idFillName='platforms' stopColorFrom='#171719' stopColorTo='#171719' className='wave-icon top_n100 right_n55' />
        )
      }
      {
        bottomWave && (
          <Wave idFillName='platforms' stopColorFrom='#171719' stopColorTo='#171719' className='wave-icon bottom_n100 left_n55' />
        )
      }

      <div className={headerClassName}>
        <div className="container">
          <div className="container__row row d-block">

            <div className="mb-3 mb-md-5">

              <h3 className={`title text-red m-0 fsz-59 ${titleClassName}`}>
                Plus de <strong className='d-bold'>250</strong> plateformes !
              </h3>
              {
                children
              }
            </div>
          </div>
        </div>
      </div>

      <div className="container">
        <div className="container__row row d-block">
          <Image
            src={data?.platformsImage?.childImageSharp?.fluid}
            className='image__fluid w-100'
            height={data?.platformsImage?.childImageSharp?.fluid?.presentationHeight}
            width={data?.platformsImage?.childImageSharp?.fluid?.presentationWidth}
          />
          {
            link && (

              <div className="platforms__action w-100 d-flex justify-content-end">
                <GradientButton className='btn-style d-bold' to={link}>
                  {linkLabel}
                </GradientButton>
              </div>
            )
          }

        </div>
      </div>
    </section>
  )
}
