const distributionDeMusicEnLigne = {
  section1: {
    mainTitle: {
      part1: `Toucher des`,
      part2: `millions d'auditeurs`,
    },
    description: `Avec notre plateforme de distribution, vous pourrez diffuser votre musique auprès de tous les principaux acteurs tels que Spotify, iTunes, Deezer, Anghami, Apple Music, Facebook, Instagram, YouTube Music, Amazon Music, Beatport, Tracksource, et plus encore.`,
    linkLabel: `Créez un compte gratuitement`,
  },
  section2: {
    mainTitle: `COMMENT VENDRE SA MUSIQUE EN LIGNE?`,
    description: `Dropzik facilite la vente, la gestion et la monétisation de votre musique en ligne dans le monde entier.`,
    cards: {
      card1: {
        title: `Uploadez Votre Musique`,
        description: `Uploadez les fichiers audios, la pochette, les informations relatives à votre sortie et choisissez parmi 250 plateformes digitales où vous souhaitez distribuer votre musique.`,
      },
      card2: {
        title: `Nous distribuions votre musique aux plateformes`,
        description: `Dropzik enverra votre projet à toutes les plateformes de streaming et de téléchargement sélectionnées. Commencez à vendre votre musique en ligne.`,
      },
      card3: {
        title: `Recevez vos revenus`,
        description: `Aucun minimum de paiement, pas de frais annuels. De multiples options de versement facilitent le transfert de vos revenus chaque mois.`,
      }
    }
  },
  section3: {
    mainTitle: `Pourquoi Dropzik ?`,
    description: `Dropzik facilite la vente, la gestion et la monétisation de votre musique en ligne dans le monde entier.`,
    linkLabel: `Créez un compte gratuitement`,
    cards: {
      card1: {
        title: `Un seul paiement par sortie`,
        description: `Pas de frais annuels pour que votre musique reste sur les plateformes. Pas de frais de renouvellement annuel pour recevoir vos revenus.`,
      },
      card2: {
        title: `Pas de versement minimum`,
        description: `Peu importe le montant, vous pouvez demander le versement de la totalité de votre solde chaque mois.`,
      },
      card3: {
        title: `Tous les outils pour gérer et suivre vos finances`,
        description: `Suivre les ventes et le flux à l’aide d’un simple tableau de bord qui vous donne accès aux résultats détaillés.`,
      },
      card4: {
        title: `Assistance`,
        description: `Notre équipe de support client répond à chaque e-mail en moins de 24H. Nous proposons également un support en arabe. Notre équipe d’assistance sera ravie de vous aider.`,
      }
    }
  },
  section4: {
    mainTitle: `NOS PLATEFORMES`,
    description: {
      part1: `Nous distribuons votre musique partout dans le monde et sur plus de 250 plateformes en ligne, dont les plus populaires au monde: Spotify, iTunes, Deezer, Anghami, Apple Music, Facebook, Instagram, YouTube Music, Amazon Music, Beatport, Tracksource et bien d’autres.`,
      part2: `Une équipe professionnelle à votre service, elle travaillera exclusivement sur la distribution de votre production musicale en ligne.`
    },
    linkLabel: `Voir la liste de nos plateformes`,
  },
  section5: {
    mainTitle: `Nos artistes et labels`,
    description: `Nous travaillons avec plusieurs artiste et labels internationaux.`,
    linkLabel: `Voir la liste de nos plateformes`,
  },
};

export default distributionDeMusicEnLigne;